import {useUserStore} from '~/stores/user'

export const needsCegepFlag = () => {
  const userStore = useUserStore()
  const { locale } = useI18n()

  const country = userStore.isAuthenticated ? userStore.userData.institution?.country : userStore.geoData.country
  const isCanada = country === 'CA'
  const isFrench = locale.value === 'fr'

  if (!isCanada || !isFrench) {
    return false
  }

  return userStore.isAuthenticated ? userStore.userData.institution?.isHigher : true
}

export const cegepFlagText = '+ CEGEP'

export const getCegepFlagHtml = () => {
  return needsCegepFlag() ? `<span class="opacity-50">${cegepFlagText}</span>` : null
}

export const addCegepFlagIfNeeded = (text: string) => {
  return needsCegepFlag() ? `${text} ${cegepFlagText}` : text
}